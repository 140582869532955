@font-face {
	font-family: Nunito;
	src: url("../public/fonts/nunito.ttf");
}

body {
	font-family: Nunito, sans-serif;
	margin: 0;

	::selection {
		background-color: #158ec7;
		color: white;
	}
}

head {
	display: none;
}

* {
	display: flex;
}
